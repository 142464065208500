/**
 * This resets and restyles the radio button.
 * Credit to: https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/
 */

/* stylelint-disable */
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="radio"] {
    --active: #4d5656;
    --active-inner: #fff;
    --focus: 2px rgba(77, 86, 86, 0.3);
    --border: #d5dbdb;
    --border-hover: #4d5656;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;

    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    cursor: pointer;
    display: inline-block;
    height: 21px;
    margin: 0;
    position: relative;
    outline: none;
    vertical-align: top;
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }

    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }

    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }

    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }

    & + label {
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 8px;
    }

    border-radius: 50%;
    &:after {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      transform: scale(var(--s, 0.7));
    }
    &:checked {
      --s: 0.5;
    }
  }
}
