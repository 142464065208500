@import "./constants";
@import "./radio";

/* https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
/* Remove default margin */
/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Set the root size */
html,
body {
  font-size: 20px;

  @media screen and (max-width: $breakpoint-tablet) {
    font-size: 18px;
  }
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  line-height: 1.5;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;

  @media screen and (max-width: $breakpoint-tablet) {
    line-height: 1.6;
  }
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  display: block;
  max-width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    /* stylelint-disable declaration-no-important */
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
    /* stylelint-enable declaration-no-important */
  }
}
