@import "../style/constants";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $color-black; // Temporary until primary colors
  font-family: $font-serif;
}

.h1 {
  letter-spacing: 1.5px;
}

.subTitle,
.p {
  color: $color-black; // Temporary until secondary colors
  font-family: $font-sans-serif;
  font-weight: $font-weight-light;
}

.subTitle {
  display: block;
  font-size: 0.67em;
}
