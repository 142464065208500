@import "../style/constants";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.nav {
  align-items: center;
  animation: fadeIn ease 2s;
  display: flex;
  justify-content: center;
  min-height: 60px;
  width: 100%;
}

.wrapper {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 420px;

  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.linkWrapper {
  position: relative;
}

.link {
  display: block;
  text-decoration: none;
  text-transform: uppercase;

  &:focus {
    outline: rgba($color-black, 0.2) auto 3px;
  }

  &::after {
    background-color: rgba($color-black, 0.08);
    bottom: -3px;
    content: "";
    height: 3px;
    left: -3px;
    margin: auto;
    position: absolute;
    transition: 200ms ease-in-out;
    width: calc(100% + 6px);
    z-index: -1;
  }

  &:hover::after {
    background-color: rgba($color-black, 0.16);
    height: calc(100% + 6px);
  }
}

.text {
  font-weight: $font-weight-regular;
}
