/* stylelint-disable color-no-hex */

// Fonts
$font-sans-serif: "Lato", sans-serif;
$font-serif: "Playfair Display", serif;

// Sans-serif font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// Primary colors
// $color-rose-vale: #aa5042;
// $color-pastel-brown: #8e6e53;
// $color-orange-red: #ff3f00;
$color-warm: #f4a460;

// Secondary colors

// Utility colors
$color-white: #fff;
$color-black: #000;
$color-transparent: rgba($color-white, 0);

// Breakpoints
$breakpoint-mobile: 425px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-desktop-large: 1440px;

// Box shadows
